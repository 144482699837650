import React from 'react';
import styles from './ServerTile.module.css';
import Tile from './Tile';

class ServerTile extends React.Component {

  render() {
    let background = null;
    if (this.props.load >= this.props.cpus || this.props.message || (!this.props.load && this.props.load !== 0)) {
      background = Tile.background.RED;
    } else if (this.props.percentage > 75) {
      background = Tile.background.ORANGE_RED;
    } else if (this.props.percentage > 50) {
      background = Tile.background.ORANGE;
    }

    let load = this.props.load || this.props.load === 0 ? this.props.load : '-';
    let cpus = this.props.cpus || this.props.cpus === 0 ? this.props.cpus : '-';
    let description = `load ${load} | ${cpus} cpu's`;
    if (this.props.message) {
      description = this.props.message;
    }

    const gaugeStyle = {
      top: Math.max(100 - this.props.percentage, 0) + '%',
    };

    return (
      <Tile
        background={ background }
        title={ this.props.title }
        value={ this.props.percentage }
        valueSuffix="%"
        description={ description }
      >
        { this.props.percentage ? <div className={styles.Gauge} style={gaugeStyle} >{ this.props.percentage }</div> : false }
      </Tile>
    );
  }

}

export default ServerTile;