import React from 'react';
import Tile from './Tile';

class AlertsTile extends React.Component {

  static type = {
    DEFAULT: 'default',
    WARNING: 'warning',
    ALARM: 'alarm',
  };

  render() {
    let background = null;
    if (this.props.type === AlertsTile.type.WARNING) {
      background = Tile.background.ALERT;
    }
    if (this.props.type === AlertsTile.type.ALARM) {
      background = Tile.background.ALARM;
    }
    return (
      <Tile
        background={ background }
        title={ this.props.title }
        value={ this.props.count > 0 ? this.props.count : false }
        description={ this.props.description }
      />
    );
  }

}

export default AlertsTile;