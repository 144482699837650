import React from 'react';
import config from '../../common/Config';
import styles from './Login.module.css';

class Login extends React.Component
{
  state = {
    error: false,
    username: config.default_user,
    password: config.default_password,
    success: false,
  };

  handleUsernameChange = (e) => {
    e.preventDefault();
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e) => {
    e.preventDefault();
    this.setState({ password: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.username, this.state.password);
    }
  };

  render() {
    let message = null;
    if (this.props.message) {
      message = <div className={styles.Message}>{ this.props.message }</div>;
    }

    return (
      <div className={styles.Login}>
        <div className={styles.TitleBar}>
          <h2>Dashboard login</h2>
        </div>
        <div className={styles.Content}>
          {message}
          <form onSubmit={this.handleSubmit}>
            <div className={styles.FormItem}>
              <label>Email</label>
              <div>
                <input type="text" value={this.state.username} onChange={this.handleUsernameChange} />
              </div>
            </div>
            <div className={styles.FormItem}>
              <label>Password</label>
              <div>
                <input type="password" value={this.state.password} onChange={this.handlePasswordChange} />
              </div>
            </div>
            <div className={styles.Actions}>
              { this.props.loading ? <div>Loading...</div> : <button>Login</button> }
            </div>
          </form>
        </div>
      </div>

    );
  }
}


export default Login;