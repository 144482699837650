import React from 'react';
import styles from './Tile.module.css';

class Tile extends React.Component
{
  static background = {
    GREEN: 'green',
    RED: 'red',
    ORANGE: 'orange',
    ORANGE_RED: 'orangeRed',
    BLUE: 'blue',
    ALERT: 'alert',
    ALARM: 'alarm',
  };

  render() {
    let classes = [styles.Tile];
    switch (this.props.background) {
      case Tile.background.RED:
        classes.push(styles.TileRed);
        break;
      case Tile.background.ORANGE:
        classes.push(styles.TileOrange);
        break;
      case Tile.background.ORANGE_RED:
        classes.push(styles.TileOrangeRed);
        break;
      case Tile.background.BLUE:
        classes.push(styles.TileBlue);
        break;
      case Tile.background.ALERT:
        classes.push(styles.TileAlert);
        break;
      case Tile.background.ALARM:
        classes.push(styles.TileAlarm);
        break;
      default:
        classes.push(styles.TileGreen);
        break;
    }

    if (this.props.loading) {
      classes.push(styles.TileLoading);
    }

    return (
      <div className={ classes.join(' ') }>
        <div className={ styles.Content }>
          <div className={ styles.Title }>
            { this.props.title }
          </div>
          <div className={ styles.Value }>
            { this.props.value } { this.props.valueSuffix ? <span className={styles.SubValue}>%</span> : false }
          </div>
          <div className={ styles.Description }>
            { this.props.description }
          </div>
        </div>
        { this.props.children }
      </div>
    )
  }
}

export default Tile;