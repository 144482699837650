import React from 'react';
import Tile from "./Tile";

class AccidentsTile extends React.Component {

  interval = null;

  state = {
    days: '-',
  };

  componentDidMount() {
    this.interval = setInterval(this.checkDays, 1000 * 60 * 60);
    this.checkDays();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkDays = () => {
    const startDate = '01-01-1989';

    const timeDiff = new Date() - new Date(startDate);
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    this.setState({
      days: days,
    });
  };

  render() {
    return (
      <Tile
        value={ this.state.days }
        description="dagen zonder ongelukken op de werkvloer"
      />
    );
  }

}

export default AccidentsTile;