import React from 'react';
import config from '../../common/Config';
import styles from './Board.module.css';
import ServerTile from './Tiles/ServerTile';
import WebsiteTile from './Tiles/WebsiteTile';
import AlertsTile from "./Tiles/AlertsTile";
import FridayTile from "./Tiles/FridayTile";
import AccidentsTile from "./Tiles/AccidentsTile";
import Login from "../Login/Login";

class Board extends React.Component
{
  shortRefreshTimer = null;
  longRefreshTimer = null;

  state = {
    loadingLogin: false,
    loadingVisitors: false,
    loadingAlerts: false,
    loadingUptime: false,
    needsLogin: true,
    message: false,
    servers: [],
    websites: [],
    websiteAlerts: 0,
    websiteAlertsError: false,
    websitesDown: [],
    hideCursor: false,
  };

  /**
   *  Get the list of servers from start.nilsson.nl,
   *  if this fails with a 403, redirect to the login page
   */
  initialize(username, password) {
    this.setState({ loadingLogin: true });
    const authenticated_options = { ...config.fetch_options };
    authenticated_options.headers.Authorization = 'Basic ' + btoa( username + ':' + password);
    
    fetch(config.api + '/status/servers', authenticated_options)
      .then((response) => {
        this.setState({ needsLogin: response.ok !== true, message: 'Login failed' });
        return response.json();
      })
      .then((json) => {
        this.setState({ servers: [...json] });
        this.startAutomaticRefresh();
      })
      .catch(() => {
        // suppress errors
      })
      .finally(() => {
        this.setState( { loadingLogin: false });
      });
  }

  startAutomaticRefresh() {
    this.loadServerStatus();
    this.loadWebsiteVisitors();
    this.loadWebsiteAlerts();
    this.loadWebsitesUptime();
    this.shortRefreshTimer = setInterval(() => {
        this.loadServerStatus();
      },
      5000
    );
    this.longRefreshTimer = setInterval(() => {
        this.loadWebsiteVisitors();
        this.loadWebsitesUptime();
        this.loadWebsiteAlerts();
      },
      10000
    );
  }

  stopAutomaticRefresh() {
    clearInterval(this.shortRefreshTimer);
    clearInterval(this.longRefreshTimer);
  }

  /**
   * Load the server values by calling the status script on the servers
   */
  loadServerStatus() {
    this.state.servers.map((server, index) => {
      fetch(server.url)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((json) => {
          const values = {...json};
          values.message = null;
          this.setServerValues(index, values);
        })
        .catch((exception) => {
          this.setServerValues(index, {
            load: null,
            cpus: null,
            percentage: null,
            message: exception.message
          });
        });
      return server;
    });
  }

  /**
   * Set new server values in the state
   *
   * @param index
   * @param values
   */
  setServerValues(index, values) {
    const newServer = {...this.state.servers[index]};
    const load = values.load ? values.load[0] : null;
    newServer.load = load;
    if (values.load) {
      newServer.percentage = Math.round((load * 100) / values.cpu_count);
      if (load > values.load[1]) {
        newServer.trend = 1;
      } else if (load < values.load[1]) {
        newServer.trend = -1;
      }
    }
    newServer.cpus = values.cpu_count;
    newServer.message = values.message;

    const newServers = [...this.state.servers];
    newServers[index] = newServer;

    this.setState({ servers: newServers });
  }

  /**
   * Load the website visitors
   */
  loadWebsiteVisitors() {
    if (this.state.loadingVisitors) {
      return;
    }
    this.setState({ loadingVisitors: true });
    fetch(config.api + '/status/websites', config.fetch_options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.message) {
          throw json;
        }
        Object.keys(json).forEach((key, index) => {
          const newWebsites = [...this.state.websites];
          newWebsites[index] = {
            name: key,
            visitors: json[key],
            message: false,
          };
          this.setState({ websites: newWebsites });
        });
      })
      .catch((exception) => {
        if (this.state.websites.length > 0) {
          this.state.websites.map((website, index) => {
            const newWebsites = [...this.state.websites];
            newWebsites[index].message = exception.message;
            this.setState({ websites: newWebsites });
            return newWebsites;
          });
        } else {
          const newWebsite = {
            message: exception.message,
          };
          this.setState({ websites: [newWebsite] });
        }
      })
      .finally(() => {
        this.setState({ loadingVisitors: false });
      });
  }

  loadWebsiteAlerts() {
    if (this.state.loadingAlerts) {
      return;
    }
    this.setState({ loadingAlerts: true });
    fetch(config.api + '/status/messages', config.fetch_options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.setState({
          websiteAlerts: json.count,
          websiteAlertsError: null,
        });
      })
      .catch((error) => {
        this.setState({
          websiteAlertsError: `Failed to load alerts (${error.message})`,
        });
      })
      .finally(() => {
        this.setState({ loadingAlerts: false });
      });
  }

  loadWebsitesUptime() {
    if (this.state.loadingUptime) {
      return;
    }
    this.setState({ loadingUptime: true });
    fetch(config.api + '/status/uptime', config.fetch_options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.setState({
          websitesDown: json.monitors ? json.monitors : [],
          websiteAlertsError: null,
        });
      })
      .catch((error) => {
        this.setState({
          websiteAlertsError: `Failed to load uptime status (${error.message})`,
        });
      })
      .finally(() => {
        this.setState({ loadingUptime: false });
      });
  }

  componentWillUnmount() {
    this.stopAutomaticRefresh();
  }

  onMouseMove = () => {
    this.setState({
      hideCursor: false
    });
    setTimeout(
      () => {
        this.setState({
          hideCursor: true
        })
      },
      2000
    );
  };

  render() {
    if (this.state.needsLogin) {
      return <Login message={ this.state.message } loading={ this.state.loadingLogin } onSubmit={
        (username, password) => {
          this.initialize(username, password);
        }
      } />;
    }

    const classes = [styles.Board];
    const cursorStyle = {
      cursor: this.state.hideCursor ? 'none' : 'default',
    };

    return (
      <div className={classes.join(' ')} onMouseMove={this.onMouseMove} style={cursorStyle}>
        { this.state.servers.map((server, index) => {
            return <ServerTile
                      key={ index }
                      title={ server.name }
                      load={ server.load }
                      percentage={ server.percentage }
                      cpus={ server.cpus }
                      message={ server.message } />
          })
        }
        { this.state.websitesDown.length ?
          <AlertsTile
            title="WEBSITES DOWN"
            count={ this.state.websitesDown.length }
            description={ 'meer info via Uptime Robot' }
            type={ AlertsTile.type.ALARM }
            loading={ this.state.loadingAlerts }
          />
          :
          <AlertsTile
            title="Website alerts"
            count={ this.state.websiteAlerts }
            description={ this.state.websiteAlertsError ? this.state.websiteAlertsError : this.state.websiteAlerts ? 'meer info op start.nilsson.nl' : 'geen problemen gerapporteerd' }
            type={ this.state.websiteAlerts || this.state.websiteAlertsError ? AlertsTile.type.WARNING : AlertsTile.type.DEFAULT }
            loading={ this.state.loadingAlerts }
          />
        }

        { this.state.websites.map((website, index) => {
            return <WebsiteTile
                      key={ index }
                      title={ website.name }
                      visitors={ website.visitors }
                      message={ website.message }
                      loading={ this.state.loadingVisitors }
                    />
          })
        }

        { this.state.websites.length + this.state.servers.length <= 16 && <FridayTile /> }
        { this.state.websites.length + this.state.servers.length <= 15 && <AccidentsTile /> }
      </div>
    );
  }
}

export default Board;
