import React from 'react';
import Tile from './Tile';

class WebsiteTile extends React.Component
{
  render() {
    let background = Tile.background.BLUE;
    if (this.props.message) {
      background = Tile.background.RED;
    } else if (this.props.visitors >= 100) {
      background = Tile.background.ORANGE;
    } else if (this.props.visitors >= 200) {
      background = Tile.background.ORANGE_RED;
    } else if (this.props.visitors >= 300) {
      background = Tile.background.RED;
    }

    return (
      <Tile
        background={ background }
        title={ `Bezoekers ${this.props.title}` }
        value={ this.props.visitors !== false ? this.props.visitors : '-' }
        description={ this.props.message }
        loading={ this.props.loading }
      />
    );
  }
}

export default WebsiteTile;