import React from 'react';
import styles from './FridayTile.module.css';
import Tile from './Tile';
import ReactPlayer from 'react-player';

class FridayTile extends React.Component {

  interval = null;

  state = {
    partyTime: false,
    countDownLabel: null,
  };

  componentDidMount() {
    this.interval = setInterval(this.checkTime, 1000);
    this.checkTime();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkTime = () => {
    // determine the time until friday 4 o'clock (shamelessly stolen
    // from a generous stranger on the internet and altered where needed)
    const today = new Date();
    const currentDayNumber = today.getDay();
    const daysToFriday = 5 - (currentDayNumber < 5 ? currentDayNumber : currentDayNumber);
    const fridayAfternoon = new Date();
    fridayAfternoon.setDate(fridayAfternoon.getDate() + daysToFriday);
    fridayAfternoon.setHours(16, 0, 0, 0);
    const ms = Math.ceil((fridayAfternoon - today) / 1000) * 1000;
    const d =  ms / 8.64e7 | 0;
    const h = (ms % 8.64e7) / 3.6e6 | 0;
    const m = (ms % 3.6e6)  / 6e4 | 0;

    // create a nice description
    let timeLeft = `${d} dagen ${h} uur en ${m} minuten`;
    if (d === 0) {
      if (h === 0) {
        if (m === 0) {
          timeLeft = 'minder dan een minuut';
        } else if (m === 1) {
          timeLeft = 'twee minuten';
        } else {
          timeLeft = `${m} minuten`;
        }
      } else {
        timeLeft = `${h} uur ${m} minuten`;
      }
    }

    // friday between 4 and 6 it's party time!
    let partyTime = daysToFriday === 0 && today.getHours() >= 16 && today.getHours() < 18;
    this.setState({
      partyTime: partyTime,
      countDownLabel: timeLeft,
    });
  };

  render() {
    return (
      <Tile>
        { this.state.partyTime ?
          <div className={ styles.Party }>
            <div>
              <div className={ styles.PartyLabel }>
                Happy hour!
              </div>
              <ReactPlayer
                className={ styles.Video }
                loop={ false }
                muted={ false }
                playing={ true }
                controls={ false }
                height={ 250 }
                width="100%"
                url="https://www.youtube.com/watch?v=BHyAVaKro3w"
              />
            </div>
          </div>
        :
          <div>
            <div className={ styles.Title }>
              Nog
            </div>
            <div className={ styles.ValueSmall }>
              { this.state.countDownLabel }
            </div>
            <div>
              tot de vrijdagmiddag borrel
            </div>
          </div>
        }
      </Tile>
    );
  }

}

export default FridayTile;
