const development = {
  DEBUG: true,
  start_url: 'http://start-nilsson.lndo.site',
  api: 'http://start-nilsson.lndo.site/api/v1-0',
  default_password: 'lekkerveilig',
};

const production = {
  DEBUG: false,
  start_url: 'https://start.nilsson.nl',
  api: 'https://start.nilsson.nl/api/v1-0',
};

let config = process.env.NODE_ENV === 'production' ? production : development;
if (window.location.hostname === 'localhost' || window.location.hostname.indexOf('lndo.site') !== -1) {
  config = development;
  console.log('Local environment detected. Using development settings.');
}

export default {
  fetch_options: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
    },
  },
  default_user: 'api',
  default_password: '',
  ...config,
};
